<template>
  <b-modal
    id="confirm-modal"
    v-model="showModal"
    size="md"
    centered
    no-stacking
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    hide-header
    @show="buttonSpinner = false"
    @hidden="buttonSpinner = false"
    aria-labelledby="Confirm dailog modal"
  >
    <template>
      <a href="javascript:void(0)" role="button" aria-labelledby="Close confirm dailog modal" class="pointer" @click="$emit(`closeConfirmModal`, `close`)">
        <img src="../../../src/assets/img/close.png" alt="close-tab" align="right" />
      </a>
    </template>
    <b-container class="py-4">
      <div class="">
        <h3 class="text-center pb-4" v-if="title">
          {{ dailogMessages[title] }}
        </h3>
        <div class="pb-3" v-if="message">
          <div class="text-center" v-if="isKey">{{ dailogMessages[message] }}</div>
          <template v-else>
            <div v-if="isHtml" v-html="message"></div>
            <div class="text-center" v-else>{{ message }}</div>
          </template>

          <!-- {{ isKey ? dailogMessages[message] : message }} -->
        </div>

        <div class="form-row pb-3">
          <b-col cols="12" sm="6" md="6" text-center>
            <b-button block variant="danger" v-activeBlur @click="onClickYes" :disabled="buttonSpinner"
              >Yes<b-spinner v-if="buttonSpinner" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
          </b-col>

          <b-col cols="12" sm="6" md="6">
            <b-button v-activeBlur :disabled="buttonSpinner" @click="$emit(`closeConfirmModal`)" block variant="outline-secondary">No</b-button>
          </b-col>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import { CONFIRM_DIALOG_MESSAGES } from '../../utilities/constants'
export default {
  name: 'ConfirmationModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    isKey: {
      type: Boolean,
      default: true,
    },
    isHtml: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      buttonSpinner: false,
      dailogMessages: CONFIRM_DIALOG_MESSAGES,
    }
  },
  methods: {
    onClickYes() {
      this.buttonSpinner = true
      this.$emit('onConfirm')
    },
  },
}
</script>
